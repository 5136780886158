<template>
  <v-col cols="4" class="headBlueMobile">
    <v-menu offset-y class="bluemenu">
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on">mdi-menu
        </v-icon>
      </template>
      <v-list>
        <v-list-item class="menuItemMobile">
          <a class="headLink" href="https://autocarsgamon.com/noticies/"
             rel="nofollow noopener" target="_blank" v-text="$ml.get('news')"/>
        </v-list-item>
        <v-list-item class="menuItemMobile">
          <a class="headLink"
             href="https://autocarsgamon.com/autocars-gamon-contacte-on-som/"
             rel="nofollow noopener" target="_blank" v-text="$ml.get('contact')"/></v-list-item>
        <v-list-item class="menuItemMobile">
          <a class="headLink" href="https://autocarsgamon.com/covid-19/"
             rel="nofollow noopener" target="_blank" v-text="$ml.get('covid')"/></v-list-item>
        <v-list-item class="menuItemMobile">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  light
                  left
                  elevation="0"
                  v-bind="attrs"
                  v-on="on">
                {{ selectedLang }}
                <v-icon
                    right
                    dark>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                  v-for="(item, index) in lang"
                  :key="index">
                <v-list-item-title @click="setLang(item.title)">{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-col>
</template>

<script>
export default {
  name: "headBlueMobile",
  props:['lang','selected','setLang']
}
</script>

<style scoped>
.headBlueMobile {
  margin-left: -100px;
}

.headBlueMobile .mdi-menu {
  color: white !important;
  top: -10px;
  left: -75px;
}
.itemBlueList {
  margin-right: 1em;
}

.itemWhiteList {
  color: #005191 !important;
  font-family: "Montserrat", Sans-serif;
  font-size: 15px;
  font-weight: 200;
  text-decoration: none;
}
</style>