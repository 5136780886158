<template>
  <v-col cols="12" md="10" align-self="center">
    <v-row v-if="!loaded">
      <v-col cols="12" md="3" v-for="i in 4" v-bind:key="i">
        <v-skeleton-loader
            type="card-avatar, article, actions"
        />
      </v-col>
    </v-row>
    <v-row v-else-if="loaded && !isMobile()">
      <v-col cols="12" md="3" v-for="travel in travels" v-bind:key="travel.ref">
        <travel-card :travel="travel"/>
      </v-col>
    </v-row>
    <v-row v-else-if="loaded && isMobile()">
      <v-col cols="12" md="12" v-for="travel in travels" v-bind:key="travel.ref">
        <mobile-travel-card :travel="travel"/>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import TravelCard from "./partials/travelCard";
import MobileTravelCard from "./partials/mobileTravelCard";

export default {
  name: "travelssection",
  components:{MobileTravelCard, TravelCard},
  props:['lang'],
  data: () => ({
    travels: [],
    loaded: false,
  }),
  mounted() {
    this.getData(this.lang)
  },
  watch:{
    lang(newlg){
      this.getData(newlg)
    }
  },
  methods: {
    getData(lng) {
      let url = ''
      if(lng === 'Esp') url = this.base_url + "client/viajes/1"
      else url = this.base_url + "client/viajes/2"
      this.axios.get(url).then(response => {
        this.travels = response.data.travels;
        this.loaded = true;
        this.$ma.trackEvent({
          category: 'Home',
          action: 'get travels',
          properties:{version:this.version}
        });
      }).catch(err=>{
        this.$ma.trackEvent({
          category: 'Home',
          action: 'failed get travels',
          properties: {errorMessage: err.response.data, errorStatus: err.response.status,version:this.version}
        });
      });
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>

</style>