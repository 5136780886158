import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axios from 'axios'
import VueMeta from 'vue-meta'
import VueAxios from 'vue-axios'
import VueSession from 'vue-session'
import VueGlobalVariable from "vue-global-var";
var VueScrollTo = require('vue-scrollto');
import VueSocialSharing from 'vue-social-sharing'
import VueCryptojs from 'vue-cryptojs'
import VueMultianalytics from 'vue-multianalytics'
import './ml'
Vue.use(VueCryptojs);
Vue.use(VueMeta);
Vue.use(VueSession);
Vue.use(VueSocialSharing);
Vue.use(VueAxios, axios);
Vue.use(VueGlobalVariable, {
  globals: {
    version: "1.1.0",
    env_url : 'https://kariba.autocarsgamon.com',
    base_url: 'https://kariba.autocarsgamon.com/API/',
    image_url: 'https://kariba.autocarsgamon.com/images/uploads/',
    bus55: [
      {line: 1, seats: ["puerta", 4, 8, 12, 16, 20, 24, 28, "puerta", 34, 38, 42, 46, 50, 55]},
      {line: 2, seats: ["No disponible", 3, 7, 11, 15, 19, 23, 27, "puerta", 33, 37, 41, 45, 49, 54]},
      {line: 3, seats: [53]},
      {line: 4, seats: [2, 6, 10, 14, 18, 22, 26, 30, 32, 36, 40, 44, 48, 52]},
      {line: 5, seats: ["conductor", 1, 5, 9, 13, 17, 21, 25, 29, 31, 35, 39, 43, 47, 51]},
    ],
    bus55CAT: [
      {line: 1, seats: ["porta", 4, 8, 12, 16, 20, 24, 28, "porta", 34, 38, 42, 46, 50, 55]},
      {line: 2, seats: ["No disponible", 3, 7, 11, 15, 19, 23, 27, "porta", 33, 37, 41, 45, 49, 54]},
      {line: 3, seats: [53]},
      {line: 4, seats: [2, 6, 10, 14, 18, 22, 26, 30, 32, 36, 40, 44, 48, 52]},
      {line: 5, seats: ["conductor", 1, 5, 9, 13, 17, 21, 25, 29, 31, 35, 39, 43, 47, 51]},
    ],
    bus63: [
      {line: 6, seats: ["puerta", 3, 7, 11, 15, 19, 23, 27, "puerta", 31, 35, 39, 43, 47, 51, 55, 59]},
      {line: 7, seats: ["No disponible", 4, 8, 12, 16, 20, 24, 28, "puerta", 32, 36, 40, 44, 48, 52, 56, 60]},
      {line: 8, seats: [63]},
      {line: 9, seats: [2, 6, 10, 14, 18, 22, 26, 30, 34, 38, 42, 46, 50, 54, 58, 62]},
      {line: 10, seats: ["conductor", 1, 5, 9, 13, 17, 21, 25, 29, 33, 37, 41, 45, 49, 53, 57, 61]},
    ],
    bus63CAT: [
      {line: 6, seats: ["porta", 3, 7, 11, 15, 19, 23, 27, "porta", 31, 35, 39, 43, 47, 51, 55, 59]},
      {line: 7, seats: ["No disponible", 4, 8, 12, 16, 20, 24, 28, "porta", 32, 36, 40, 44, 48, 52, 56, 60]},
      {line: 8, seats: [63]},
      {line: 9, seats: [2, 6, 10, 14, 18, 22, 26, 30, 34, 38, 42, 46, 50, 54, 58, 62]},
      {line: 10, seats: ["conductor", 1, 5, 9, 13, 17, 21, 25, 29, 33, 37, 41, 45, 49, 53, 57, 61]},
    ],
    bus71: [
      {line: 11, seats: ["puerta", 4, 8, 12, 16, 20, 24, 28, 32, "puerta", 36, 42, 46, 50, 54, 58, 62, 66, 71]},
      {line: 12, seats: ["No disponible", 3, 7, 11, 15, 19, 23, 27, 31, "puerta", 35, 41, 45, 49, 53, 57, 61, 65, 70]},
      {line: 13, seats: [69]},
      {line: 14, seats: [2, 6, 10, 14, 18, 22, 26, 30, 34, 38, 40, 44, 48, 52, 56, 60, 64, 68]},
      {line: 15, seats: ["conductor", 1, 5, 9, 13, 17, 21, 25, 29, 33, 37, 39, 43, 47, 51, 55, 59, 63, 67]},
    ],
    bus71CAT: [
      {line: 11, seats: ["porta", 4, 8, 12, 16, 20, 24, 28, 32, "porta", 36, 42, 46, 50, 54, 58, 62, 66, 71]},
      {line: 12, seats: ["No disponible", 3, 7, 11, 15, 19, 23, 27, 31, "porta", 35, 41, 45, 49, 53, 57, 61, 65, 70]},
      {line: 13, seats: [69]},
      {line: 14, seats: [2, 6, 10, 14, 18, 22, 26, 30, 34, 38, 40, 44, 48, 52, 56, 60, 64, 68]},
      {line: 15, seats: ["conductor", 1, 5, 9, 13, 17, 21, 25, 29, 33, 37, 39, 43, 47, 51, 55, 59, 63, 67]},
    ]
  }
});
Vue.use(VueScrollTo)
Vue.config.productionTip = false
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})
let mixpanelConfig = {
  token: '93353099d4d6faa3a0f4e5c925240772'
}

Vue.use(VueMultianalytics, {
  modules: {
    mixpanel: mixpanelConfig
  }
})
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
