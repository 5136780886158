<template>
  <v-col cols="12" style="padding: 0px !important;">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2600 131.1" preserveAspectRatio="none" height="100%">
      <path class="elementor-shape-fill" d="M0 0L2600 0 2600 69.1 0 0z" style="fill:#D52787"></path>
      <path class="elementor-shape-fill" d="M0 0L2600 0 2600 69.1 0 69.1z" style="opacity:0.5;fill:#D52787"></path>
      <path class="elementor-shape-fill" d="M2600 0L0 0 0 130.1 2600 69.1z" style="opacity:0.25;fill:#D52787"></path>
    </svg>
  </v-col>
</template>

<script>
export default {
  name: "svgTopGradient"
}
</script>

<style scoped>

</style>