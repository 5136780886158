<template>

  <v-row class="rowMobileHead" justify="center">
    <v-col
        class="text-right justify-end"
        cols="12" md="4">
      <router-link to="/">
        <v-img src="../../assets/kariba.png" width="150" class="ml-15 mt-15 text-right"/>
      </router-link>
    </v-col>
    <v-col
        class="text-center mt-15"
        cols="12" md="6">
      <h2 class="subheading" v-text="$ml.get('karibaClaim')"/>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "videoMobile"
}
</script>

<style scoped>
.rowMobileHead {
  background: url('../../assets/gamonMobile.png');
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.43);
  position: relative;
  height: 250px;
  background-size: cover;
}
.rowMobileHead .subheading {
  top:-90px;
  position: relative;
  font-size: 18pt;
}
</style>