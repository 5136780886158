<template>
  <v-col cols="3">
    <ul style="list-style:none; display: flex" class="pt-2">
      <li><a href="https://www.facebook.com/autocars.gamon/" rel="nofollow noopener" target="_blank">
        <v-icon class="headIcon" small>fab fa-facebook-f</v-icon>
      </a></li>
      <li><a href="https://www.instagram.com/autocarsgamon/" rel="nofollow noopener" target="_blank">
        <v-icon class="ml-3 headIcon" small>fab fa-instagram</v-icon>
      </a></li>
      <li><a href="https://twitter.com/AutocarsGamon" rel="nofollow noopener" target="_blank">
        <v-icon class="ml-3 headIcon" small>fab fa-twitter</v-icon>
      </a></li>
      <li><a
          href="https://www.linkedin.com/company/grup-gam%C3%B3n/?trk=public_profile_topcard_current_company&originalSubdomain=es"
          rel="nofollow noopener" target="_blank">
        <v-icon class="ml-3 headIcon" small>fab fa-linkedin</v-icon>
      </a></li>
    </ul>
  </v-col>
</template>

<script>
export default {
  name: "socialHeadColumn"
}
</script>

<style scoped>

</style>