<template>
  <v-parallax id="footerLax" dark :height="getFooterHeight()"
              src="https://autocarsgamon.com/wp-content/uploads/2019/10/Autocares-Gamon-footer.jpg">

    <v-container fluid>
      <v-row justify="center" class="footerRow mt-15 ">
        <v-col cols="12" md="2">
          <h4 class="mb-5" v-text="$ml.get('services.title')"/>
          <ul style="list-style: none">
            <li><a href="https://autocarsgamon.com/linies-regulars/" rel="nofollow" target="_blank"
                   v-text="$ml.get('services.publicTransport')"/></li>
            <li><a href="https://autocarsgamon.com/lloguer-dautocars-serveis-a-mida-2/" rel="nofollow"
                   target="_blank" v-text="$ml.get('services.busRent')"/></li>
            <li><a href="https://kariba.autocarsgamon.com" rel="nofollow" target="_blank"
                   v-text="$ml.get('services.travelAgency')"/></li>
            <li><a href="https://autocarsgamon.com/inbus-serveis-escolars/" rel="nofollow" target="_blank"
                   v-text="$ml.get('services.school')"/></li>
          </ul>
        </v-col>
        <v-col cols="12" md="2">
          <h4 class="mb-5" v-text="$ml.get('info.title')"/>
          <ul style="list-style: none">
            <li><a href="https://autocarsgamon.com/linies-regulars/" rel="nofollow" target="_blank"
                   v-text="$ml.get('info.schedules')"/></li>
            <li><a href="https://autocarsgamon.com/faq/" rel="nofollow" target="_blank"
                   v-text="$ml.get('info.faq')"/></li>
            <li><a href="https://autocarsgamon.com/formular-objectes-perduts/" rel="nofollow" target="_blank"
                   v-text="$ml.get('info.objects')"/></li>
            <li><a href="https://autocarsgamon.com/noticies/" rel="nofollow" target="_blank"
                   v-text="$ml.get('info.lastNews')"/></li>
          </ul>
        </v-col>
        <v-col cols="12" md="2">
          <h4 class="mb-5" v-text="$ml.get('kariba.title')"/>
          <ul style="list-style: none">
            <li><a href="https://kariba.autocarsgamon.com" rel="nofollow" target="_blank"
                   v-text="$ml.get('kariba.travel')"/></li>
            <li><a href="https://kariba.group-team.com/" rel="nofollow" target="_blank"
                   v-text="$ml.get('kariba.search')"/></li>
            <li><a href="https://autocarsgamon.com/#" rel="nofollow" target="_blank"
                   v-text="$ml.get('kariba.newsletter')"/></li>
            <li><a href="https://autocarsgamon.com/condicions-generals/" rel="nofollow" target="_blank"
                   v-text="$ml.get('kariba.conditions')"/></li>
          </ul>
        </v-col>
        <v-col cols="12" md="2">
          <h4 class="mb-5" v-text="$ml.get('about.title')"/>
          <ul style="list-style: none">
            <li><a href="https://autocarsgamon.com/empresa/" rel="nofollow" target="_blank"
                   v-text="$ml.get('about.enterprise')"/></li>
            <li><a href="https://autocarsgamon.com/empresa/#flota" rel="nofollow" target="_blank"
                   v-text="$ml.get('about.flota')"/></li>
            <li><a href="https://autocarsgamon.com/enquestes-serveis-linia-regular/" rel="nofollow"
                   target="_blank" v-text="$ml.get('about.certifications')"/></li>
            <li><a href="https://autocarsgamon.com/autocars-gamon-borsa-treball/" rel="nofollow"
                   target="_blank" v-text="$ml.get('about.workWithUs')"/></li>
            <li><a href="https://autocarsgamon.com/autocars-gamon-contacte-on-som/" rel="nofollow"
                   target="_blank" v-text="$ml.get('about.contact')"/></li>
          </ul>
        </v-col>
        <v-col cols="12" md="3" class="pr-10 text-right">
          <a href="https://www.facebook.com/autocars.gamon/" rel="nofollow" target="_blank">
            <v-icon>fab fa-facebook-f</v-icon>
          </a>
          <a href="https://www.instagram.com/autocarsgamon/?hl=es" rel="nofollow" target="_blank">
            <v-icon class="ml-5">fab fa-instagram</v-icon>
          </a>
          <a href="https://twitter.com/AutocarsGamon" rel="nofollow" target="_blank">
            <v-icon class="ml-5">fab fa-twitter</v-icon>
          </a>
          <a href="https://www.linkedin.com/company/grup-gam%C3%B3n/?trk=public_profile_topcard_current_company&originalSubdomain=es"
             rel="nofollow" target="_blank">
            <v-icon class="ml-5">fab fa-linkedin</v-icon>
          </a>
        </v-col>
        <v-col cols="12" class="text-center mt-n10" >
          <p style="text-align: center;">Pol. Ind. de Torrefarrera · Camí de les Comes 11, 25123 Torrefarrera
            (Lleida)
            · <a href="tel:973750090">973 75 00 90</a> · <a href="mailto:autocars@autocarsgamon.com">autocars@autocarsgamon.com</a>
          </p>
        </v-col>
        <v-col cols="6" class="text-center legalLinks">
          <ul>
            <li>
              <a href="https://autocarsgamon.com/avis-legal/" target="_blank" class="mr-3 font-weight-bold"
                 v-text="$ml.get('legal')"/>
            </li>
            <li>
              <a href="https://autocarsgamon.com/termes-us/" target="_blank" class="mr-3 font-weight-bold"
                 v-text="$ml.get('terms')"/>
            </li>
            <li>
              <a href="https://autocarsgamon.com/site-map-mapa-web/" target="_blank" class="mr-3 font-weight-bold"
                 v-text="$ml.get('map')"/>
            </li>
            <li>
              <a href="https://autocarsgamon.com/politica-de-cookies/" target="_blank" class="mr-3 font-weight-bold"
                 v-text="$ml.get('cookies')"/>
            </li>
          </ul>
        </v-col>
        <v-col cols="12" md="12" class="footerImages text-center mt-n10">
          <a href="https://autocarsgamon.com" rel="nofollow" target="_blank"> <img
              src="https://autocarsgamon.com/wp-content/uploads/2019/08/cropped-LOGO-GAMON.png" width="150"
              class="mr-5"/></a>
          <img src="https://autocarsgamon.com/wp-content/uploads/2019/08/LOGO-KARIBA.png" width="150"
               class="mr-5" style="top: 15px;position: relative;"/>
          <a href="https://autocarsgamon.com/autocars-lax/" rel="nofollow" target="_blank"> <img
              src="https://autocarsgamon.com/wp-content/uploads/2019/08/LOGO-LAX.png" width="150" class="mr-5"
              style="top: 15px;position: relative;"/></a>
          <a href="https://autocarsgamon.com/el-portal-del-pirineo/" rel="nofollow" target="_blank"> <img
              src="https://autocarsgamon.com/wp-content/uploads/2019/08/LOGO-PORTAL.png" width="150"
              class="mr-5" style="top: 15px;position: relative;"/></a>
          <a href="http://inbus.app/" rel="nofollow" target="_blank"> <img
              src="https://autocarsgamon.com/wp-content/uploads/2021/05/inBUS.png" width="150" class="mr-5"
              style="top: 15px;position: relative;"/></a>

        </v-col>
        <v-col cols="12" md="4" class="text-center">
          <a href="https://autocarsgamon.com/fons-feder" rel="nofollow" target="_blank"> <img
              src="https://autocarsgamon.com/wp-content/uploads/2019/10/LOGO-UE-FEDER_300.png" class="mr-10"
              :width="getUEWidth()"/></a>
          <a href=" https://www.bodas.net/autobuses/autocars-gamon--e94926" rel="nofollow" target="_blank"><img
              src="https://cdn1.bodas.net/img/seals/seal_bodas_es_ES.png" :width="getBODASWidth()"/></a>
        </v-col>
      </v-row>
    </v-container>
  </v-parallax>
</template>

<script>
export default {
  name: "footerDesktop",
  props: ['getFooterHeight','getUEWidth','getBODASWidth']
}
</script>

<style scoped>
.legalLinks ul{
  list-style: none;
}
.legalLinks li{
  float: left;
  display: block;
  color: white;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}
</style>