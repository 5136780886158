<template>
  <v-app v-if="$route.name === 'iframe'">
    <v-main>
      <v-container fluid>
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
  <v-app v-else>
    <v-row justify="center" class="headBlue pl-15 no-print">
      <head-blue-desktop :lang="lang" :selected="selectedLang" :set-lang="setLang" v-if="!isMobile()"/>
      <head-blue-mobile  :lang="lang" :selected="selectedLang" :set-lang="setLang" v-else/>
      <v-spacer v-if="!isMobile()" />
      <social-head-column />
    </v-row>
    <head-white-desktop v-if="!isMobile()"  class="no-print"/>
    <head-white-mobile v-else />
    <video-desktop v-if="!isMobile()" class="no-print"/>
    <video-mobile v-else/>
    <v-main>
      <v-container fluid>
        <router-view/>
      </v-container>
    </v-main>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2600 131.1" preserveAspectRatio="none"
         style="transform:rotate(180deg)" height="100"  class="no-print">
      <path class="elementor-shape-fill " d="M0 0L2600 0 2600 69.1 0 0z" style="fill:#D52787"></path>
      <path class="elementor-shape-fill" style="opacity:0.5;fill:#D52787" d="M0 0L2600 0 2600 69.1 0 69.1z"></path>
      <path class="elementor-shape-fill" style="opacity:0.25;fill:#D52787" d="M2600 0L0 0 0 130.1 2600 69.1z"></path>
    </svg>

    <v-footer padless color="lightpink" dark :height="getFooterHeight()"  class="no-print">
      <footer-desktop v-if="!isMobile()" :get-footer-height="getFooterHeight" :get-b-o-d-a-s-width="getBODASWidth" :get-u-e-width="getUEWidth"/>
      <footer-mobile v-else :get-footer-height="getFooterHeight" :get-b-o-d-a-s-width="getBODASWidth" :get-u-e-width="getUEWidth"/>
    </v-footer>
  </v-app>
</template>

<script>
import HeadBlueDesktop from "./components/general/headBlueDesktop";
import HeadBlueMobile from "./components/general/headBlueMobile";
import SocialHeadColumn from "./components/general/socialHeadColumn";
import HeadWhiteMobile from "./components/general/headWhiteMobile";
import HeadWhiteDesktop from "./components/general/headWhiteDesktop";
import VideoDesktop from "./components/general/videoDesktop";
import VideoMobile from "./components/general/videoMobile";
import FooterDesktop from "./components/general/footerDesktop";
import FooterMobile from "./components/general/footerMobile";

export default {
  name: 'App',
  data: () => ({
    lang: [
      {title: 'ESP'},
      {title: 'CAT'},
    ],
    selectedLang: 'CAT'
  }),
  components: {
    FooterMobile,
    FooterDesktop,
    VideoMobile,
    VideoDesktop,
    HeadWhiteDesktop,
    HeadWhiteMobile,
    SocialHeadColumn,
    HeadBlueMobile,
    HeadBlueDesktop,
  },
  mounted() {
    if(!this.$session.exists()){
      this.setLang(this.$ml.current)
      this.$session.start();
    }
    else
      this.setLang(this.$session.get('lang'))
  },
  methods: {
    setLang(lang) {
      this.selectedLang = lang;
      this.$session.set('lang',lang);
      if (lang === 'ESP') this.$ml.change('Esp');
      else this.$ml.change('Cat');
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return window.innerWidth <= 768;
      }
    },
    getFooterHeight() {
      if (this.isMobile()) return 1200;
      else return 570;
    },
    getUEWidth() {
      if (this.isMobile()) return 200;
      else return 200;
    },
    getBODASWidth() {
      if (this.isMobile()) return 200;
      else return 150;
    }
  }
};
</script>
<style>
a {
  outline-width: 0 !important;
}

.headBlue {
  background: rgb(0, 81, 145) none repeat scroll 0% 0%;
  height: 55px !important;
}

.headWhite {
  background: white;
  z-index: 1;
}

.headLink {
  color: white !important;
  font-family: "Montserrat", Sans-serif;
  font-size: 15px;
  font-weight: 200;
  text-decoration: none;
}

.menuLink {
  text-decoration: none;
  outline-width: 0 !important;
}

.headIcon {
  color: lightgray !important;
  font-size: 10px;
  font-weight: 200;
}
.itemWhiteList {
  color: #005191 !important;
  font-family: "Montserrat", Sans-serif;
  font-size: 15px;
  font-weight: 200;
  text-decoration: none;
}


.subheading {
  font-family: "Give You Glory", Sans-serif;
  font-size: 2.3em;
  font-weight: bold;
  font-style: normal;
  line-height: 1.9em;
  color: white;
}

#headLax .v-parallax__content {
  background: rgba(0, 0, 0, 0.3);
}

#footerLax .v-parallax__content {
  background: rgba(0, 0, 0, 0.7);
}

.footerRow {
}



.footerRow h4 {
  color: #d1d0cd;
  font-family: "Raleway", Sans-serif;
  font-size: 1em;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}

.footerRow li {
  font-family: "Raleway", Sans-serif;
  font-size: 0.8em;
  font-weight: 500;
  line-height: 2em;
  letter-spacing: 1.5px;
  color: #ffffff;
  padding-left: 0em;
  margin-left: -1.5em;
}

.footerRow li a {
  color: #ffffff !important;
}



.footerRow li a:hover {
  color: #7A7A7A !important;
}

.footerRow p {
  color: #ffffff;
  font-family: "Montserrat", Sans-serif;
  font-size: 0.8em;
  line-height: 1.5em;
}

.footerRow p a {
  color: #d1d0cd !important;
}

.footerRow p a:hover {
  color: #7A7A7A !important;
}

.menuItemMobile a {
  color: #7A7A7A !important;
}

.footerImages img {
  filter: brightness(0) invert(1);
  margin: 0 auto;
}








.v-menu__content.theme--light.menuable__content__active {
  min-width: 90% !important;
}




</style>
