<template>
  <v-row class="headWhite pl-15">
    <v-col cols="4">
      <a href="https://autocarsgamon.com/" rel="nofollow" target="_blank">
        <v-img src="https://autocarsgamon.com/wp-content/uploads/2019/08/LOGO-Gamon-grup.png" width="120"
               class="ml-16 text-right"/>
      </a>
    </v-col>
    <v-col cols="8">
      <ul style="list-style:none; display: flex" class="pt-2 ">
        <li class="itemWhiteList">
          <a class="menuLink" style="text-decoration: none; outline-width:0"
             href="https://autocarsgamon.com/linies-regulars/" rel="nofollow noopener"
             target="_blank" v-text="$ml.get('publicTransport')"/>
        </li>
        <li class="itemWhiteList ml-5">

          <a class="menuLink"
             href="https://autocarsgamon.com/lloguer-dautocars-serveis-a-mida/" rel="nofollow noopener"
             target="_blank" v-text="$ml.get('busRent')"/></li>
        <li class="itemWhiteList ml-5"><a class="menuLink" :href="env_url"
                                          rel="nofollow noopener" target="_blank" v-text="$ml.get('travelAgency')"/>
        </li>
        <li class="itemWhiteList ml-5"><a class="menuLink"
                                          href="https://autocarsgamon.com/escolabus-serveis-escolars/"
                                          rel="nofollow noopener" target="_blank" v-text="$ml.get('school')"/></li>
      </ul>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "headWhiteDesktop"
}
</script>

<style scoped>

</style>