<template>
  <v-col cols="4">
    <ul style="list-style:none; display: flex" class="ml-10 pt-2">
      <li class="itemBlueList"><a class="headLink" href="https://autocarsgamon.com/noticies/"
                                  rel="nofollow noopener" target="_blank" v-text="$ml.get('news')"/></li>
      <li class="itemBlueList"><a class="headLink"
                                  href="https://autocarsgamon.com/autocars-gamon-contacte-on-som/"
                                  rel="nofollow noopener" target="_blank" v-text="$ml.get('contact')"/></li>
      <li class="itemBlueList"><a class="headLink" href="https://autocarsgamon.com/covid-19/"
                                  rel="nofollow noopener" target="_blank" v-text="$ml.get('covid')"/></li>
      <li class="itemBlueList">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                style="background: rgb(0, 81, 145) none repeat scroll 0% 0%;margin-top: -5px;"
                dark
                elevation="0"
                v-bind="attrs"
                v-on="on">
              {{ $ml.current }}
              <v-icon
                  right
                  dark>
                mdi-menu-down
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
                v-for="(item, index) in lang"
                :key="index">
              <v-list-item-title @click="setLang(item.title)" v-text="item.title" />
            </v-list-item>
          </v-list>
        </v-menu>
        <ul class="langList" style="min-width: 0 !important;">
          <li>ESP</li>
          <li>CAT</li>
        </ul></li>
    </ul>
  </v-col>
</template>

<script>
export default {
  name: "headBlueDesktop",
  props: ['lang', 'selected', 'setLang']
}
</script>

<style scoped>
.itemBlueList {
  margin-right: 1em;
}
.langList{
  min-width: 0 !important;
  width: 150px !important;
  box-shadow: none !important;
}

</style>
