<template>
  <v-col cols="10">
    <v-row>
      <v-col cols="12" md="4">
        <v-img
            src="images/kariba-programa-viajes.jpeg"
            class="white--text align-end justify-center"
            height="400px"
            aspect-ratio="1.7"
            contain>
        </v-img>
      </v-col>
      <v-col cols="12" md="4" v-if="!sendOK">
        <v-row>
          <v-col cols="12">
            <h3 class="newsTitle">Newsletter</h3>
          </v-col>
          <v-col cols="12" class="mb-n10">
            <p class="newsSubtitle"  v-text="$ml.get('newsletterFooter.title')" />
          </v-col>
          <v-col cols="6">
            <v-text-field
                class="ml-5 newsFooter"
                color="#D52687"
                :label="$ml.get('newsletterFooter.name')"/>
          </v-col>
          <v-col cols="6">
            <v-text-field
                class="mr-5 newsFooter"
                color="#D52687"
                :label="$ml.get('newsletterFooter.phone')"/>
          </v-col>
          <v-col cols="12">
            <v-text-field
                class="mr-5 ml-5 newsFooter"
                color="#D52687"
                :label="$ml.get('newsletterFooter.email')"/>
          </v-col>
          <v-col cols="8" class="checkCol">
            <v-checkbox
                v-model="acceptTerms"
                color="#d81d86">
              <template v-slot:label>
                <div @click.stop="" style="color:#D52787;font-size: 10pt">
                  <span v-text="$ml.get('newsletterFooter.read')" />
                  <a href="https://autocarsgamon.com/termes-us/" rel="nofollow" target="_blank" >
                    <span v-text="$ml.get('newsletterFooter.terms')" />
                  </a>
                </div>
              </template>
            </v-checkbox>

          </v-col>
          <v-col cols="4" class="checkColBT">
            <v-btn
                class="ma-2"
                outlined
                :loading="load"
                @click="sendNews"
                color="#D52787">
              ENVIAR
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="4" class="mt-15" style="text-align: center" v-else>
        <h3 style=" color: #D52787; font-weight: 500" v-text="$ml.get('newsletter.thanks')"/>
      </v-col>
      <v-col cols="12" md="4">
        <v-img
            v-if="$ml.current === 'Esp'"
            src="images/ofertas.jpg"
            class="white--text align-end"
            height="400px"
            aspect-ratio="1.7"
            contain>
          <v-btn color="#D52787" class="text-left footBT">
            <a style="color:white;font-weight: bold" href="https://kariba.group-team.com/" target="_blank" rel="noopener nofollow">Descúbrelas</a>
          </v-btn>
        </v-img>
        <v-img
            v-if="$ml.current === 'Cat'"
            src="https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img/https://autocarsgamon.com/wp-content/uploads/2020/01/Cercador-ofertes-Kariba-1.jpg"
            class="white--text align-end"
            height="325px"
            aspect-ratio="1.7"
            contain>
          <v-btn color="#D52787" class="text-left footBT">
            <a style="color:white;font-weight: bold" href="https://kariba.group-team.com/" target="_blank" rel="noopener nofollow">Descobreix-les</a>
          </v-btn>
        </v-img>
      </v-col>
    </v-row>
  </v-col>

</template>

<script>
export default {
  name: "newsletterbottomsection",
  data(){
    return{
      acceptTerms:false,
      sendOK:false,
      load:false
    }
  },
  methods:{
    sendNews(){
      this.load = true;
      setTimeout(function () { this.sendOK = true; this.load = false; }.bind(this), 1500)
    }
  }
}
</script>

<style scoped>
.newsTitle {
  padding: 10px 10px 10px 10px;
  background-color: #d81d86;
  font-family: "Playfair Display", Sans-serif;
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 30px;
}

.newsSubtitle {
  color: #767676;
  font-family: "Montserrat", Sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.2em;
  letter-spacing: 1px;
  text-align: center;
}

.checkCol {
  margin-top: -2em;
}

.checkColBT {
  margin-top: -1em;
}

.footBT {
  margin: 0 auto;
  display: block ruby;
}
.newsFooter input::placeholder {
  color: #b52787!important;
  opacity: 1;
}

.newsFooter  .v-label {
  color: #b52787;
  opacity: 1;
}

.newsFooter  input,
.newsFooter input{
  color: #b52787!important;
}
</style>