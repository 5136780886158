<template>
  <v-row align="center" justify="center">
    <newslettertopsection/>
    <svg-top-gradient/>
    <travelssection :lang="$ml.current"/>
    <newsletterbottomsection/>
  </v-row>
</template>

<script>
// @ is an alias to /src
import SvgTopGradient from "../components/partials/svgTopGradient";
import Newslettertopsection from "../components/home/newslettertop_section";
import Travelssection from "../components/home/travels_section";
import Newsletterbottomsection from "../components/home/newsletterbottom_section";

export default {
  name: 'Home',
  components: {Newsletterbottomsection, Travelssection, Newslettertopsection, SvgTopGradient},
  mounted() {this.refreshUnconfirmedReservations()},
  methods: {
    refreshUnconfirmedReservations() {
      let url = this.base_url + "/admin/borrarpendientes";
      this.axios.get(url).then(() => {
      })
    }
  },
  data: () => ({
    loaded: false,
    acceptTerms: false,
    terms: false
  }),
}
</script>

<style scoped>


.nextTravels {
  color: #d81d86;
  font-family: "Playfair Display", Sans-serif;
  font-size: 2em;
  font-weight: normal;
  text-transform: none;
}
</style>
