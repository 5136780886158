import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/viaje/:ref',
    name: 'Viaje',
    component: () => import('../views/Travel.vue')
  },
  {
    path: '/iframe/:lang',
    name: 'iframe',
    component: () => import('../views/IFrame.vue')
  },
  {
    path: '/errorenpago/:ref/:idc',
    name:'Error en pago',
    component: () => import('../views/PaymentError.vue')
  },
  {
    path: '/paytest',
    name:'testpayment',
    component: () => import('../views/testnot.vue')
  },
  {
    path: '/confirmacion/:ref/:idc',
    name:'confirmacion',
    component: () => import('../views/Confirmation.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
