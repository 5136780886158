<template>
  <v-card
      :loading="loading"
      class="mx-auto my-12"
      max-width="374"
      :height="height">
    <router-link :to="'/viaje/'+travel.ref">
    <v-img height="200"
           :src="this.image_url+travel.image"/>
    <v-card-title>
        <h3 class="titleCard" v-if="$ml.current === 'Esp'">{{ travel.titleES }} </h3>
        <h3 class="titleCard" v-else>{{ travel.titleCAT }}</h3>
    </v-card-title>
    </router-link>
    <v-card-text class="subtitleTravel">
      <div class="my-4 subtitle-1">
        <i class="far fa-calendar-alt"/> {{ date }}
      </div>
      <div class="my-4 subtitle-1" v-if=" travel.duration==='1'">
        <i class="far fa-clock"/> {{ travel.duration }} día
      </div>
      <div class="my-4 subtitle-1" v-else>
        <i class="far fa-clock"/> {{ travel.duration }} días
      </div>
      <div class="my-4 subtitle-1">
        <i class="fas fa-wallet"/> {{ travel.price }} €
      </div>
      <div class="my-4 subtitle-1">
        <v-alert
            v-if="quantity<10  && quantity>0"
            dense
            outlined
            color="#b52787"
            type="error" v-text="$ml.get('travelCard.lastSeats')"/>
      </div>
    </v-card-text>
    <v-card-actions class="footerCard" v-bind:class="{disabled : quantity<=0}">
      <v-btn light v-if="quantity>0" class="reserButton"  @click="openTravel" color="#d81d86"
             v-text="$ml.get('travelCard.reservar')"/>
      <v-btn v-else disabled style="color:white" text v-text="$ml.get('travelCard.outOfStock')"/>
      <v-spacer></v-spacer>
      <ShareNetwork
          class="mr-1"
          network="facebook"
          title=""
          :url="env_url+'/viaje/'+travel.ref"
          :quote="$ml.get('travelCard.shareMessage') ">
        <i class="rrssIcon fab fa-facebook-f"/>
      </ShareNetwork>
      <ShareNetwork
          network="twitter"
          class="mr-1"
          :url="env_url+'/viaje/'+travel.ref"
          :title="$ml.get('travelCard.shareMessage') ">
        <i class="rrssIcon fab fa-twitter"></i>
      </ShareNetwork>
      <ShareNetwork
          network="whatsapp"
          :url="env_url+'/viaje/'+travel.ref"
          :title="$ml.get('travelCard.shareMessage') ">
        <i class="rrssIcon fab fa-whatsapp"/>
      </ShareNetwork>
    </v-card-actions>
  </v-card>
</template>

<script>
import {isTablet} from 'mobile-device-detect'
export default {
  name: "travelCard",
  props: ['travel'],
  data: () => ({
    loading: false,
    height: 625,
    quantity: 0,
    date: '',
    tbt:'a',
  }),
  mounted() {
    this.quantity = (parseInt(this.travel.places) - parseInt(this.travel.reservations));
    if (this.quantity <= 10 && this.quantity > 0) this.height = 585
    else this.height = 485
    const splitDate = this.travel.date.split('-');
    this.date = splitDate[2] + '-' + splitDate[1] + '-' + splitDate[0]
    this.tbt = isTablet
  },

  methods: {
    openTravel() {
      this.$router.push('/viaje/' + this.travel.ref);
    }
  },
  computed:{

    lang(){
      return this.$ml.current;
    }
  },
  watch:{
    lang(){
      console.log('cambia idioma')
    }
  }
}
</script>

<style scoped>
.titleCard{
  display: -webkit-box;
  max-width: 400px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 55px;
  z-index: 1;
}

.subtitleTravel{
  margin-top: -25px;
}
.rrssIcon {
  color: #d81d86 !important;
}

.footerCard {
  bottom: 0;
  position: absolute;
  width: 100%;
  border-top:2px solid #d81d86;
  color: white !important;
}
.reserButton{
  width: 130px;
}
.subtitle-1{
  font-size: 1.15rem !important
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
  .titleCard{
    font-size: 11pt;

  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
  .titleCard{
    font-size: 11pt;

  }
  .reserButton{
    width: 100px;
  }
}
</style>