import Vue from 'vue'
import {MLanguage, MLCreate, MLInstaller} from 'vue-multilanguage'

Vue.use(MLInstaller)

export default new MLCreate({
    initial: 'Esp',
    save: process.env.NODE_ENV === 'production',
    languages: [
        new MLanguage('Esp').create({
            news: 'NOTICIAS',
            contact: 'CONTACTO',
            covid: 'COVID-19',
            publicTransport: 'TRANSPORTE PÚBLICO',
            busRent: 'ALQUILER DE AUTOCARES',
            travelAgency: 'AGENCIA DE VIAJES',
            school: 'SERVICIOS ESCOLARES',
            karibaClaim: 'Cuando cada instante del viaje se convierte en un gran recuerdo',
            services: {
                title: 'SERVICIOS',
                publicTransport: 'Transporte público',
                busRent: 'Alquiler de autocares',
                travelAgency: 'Agencia de viajes',
                school: 'Servicios escolares',
            },
            info: {
                title: 'TE INFORMAMOS',
                schedules: 'Consulta los horarios',
                faq: 'FAQ',
                objects: 'Objetos perdidos',
                lastNews: 'Últimas noticias'
            },
            kariba: {
                title: 'KARIBA',
                travel: 'Viajes',
                search: 'Buscador de ofertas',
                newsletter: 'Newsletter',
                conditions: 'Condiciones de viajeros'
            },
            about: {
                title: '¿QUIÉNES SOMOS?',
                enterprise: 'Empresa',
                flota: 'Flota',
                certifications: 'Certificaciones',
                workWithUs: 'Trabaja con nosotros',
                contact: 'Contacto'
            },
            legal: 'Aviso legal',
            terms: 'Política de privacidad',
            map: 'Mapa web',
            cookies: 'Política de cookies',
            newsletter: {
                thanks: 'Gracias por suscribirte a la newsletter de Kariba',
                title: 'Suscríbete para recibir las últimas promociones y viajes',
                email: 'Introduce tu E-Mail',
                button: 'Suscribirme'
            },
            newsletterFooter:{
                title:'Suscríbete al newsletter de Viajes Kariba para recibir las últimas noticias y ofertas',
                name: 'NOMBRE',
                phone: 'TELÉFONO',
                email: 'E-MAIL',
                read: 'He leído y acepto los ',
                terms: 'términos y condiciones'
            },
            travelCard: {
                lastSeats: 'Últimas Plazas Disponibles',
                reservar: '+ INFO',
                outOfStock: 'Agotado',
                shareMessage: 'Mira este viaje que encontré en kariba:'
            },
            travel: {
                link: 'Ver todos los viajes >',
                head: {
                    date: 'Fecha: ',
                    duration: 'Duración: ',
                    price: 'Precio: ',
                    suplement: 'Suplemento: ',
                    reservar: 'Reservar',
                    day: 'día',
                    days: 'dias'
                },
                terms: 'Términos y condiciones del viaje',
                busSeats: {
                    door: 'Puerta',
                    driver: 'Conductor',
                    reserved: 'Reservado'
                },
                form: {
                    title: 'Reserva tu viaje ahora',
                    persons: 'Selecciona el número de personas',
                    personsSelect: '¿Cuantas personas vais a viajar?',
                    seats: 'Selecciona los asientos',
                    busySeat: 'No disponible',
                    freeSeat: 'Libre',
                    reservatedSeat: 'Mis asientos',
                    rooms: {
                        title: 'Selecciona número habitaciones',
                        sup: 'Suplemento',
                        indiv: 'Hab. individual',
                        double: 'Hab. doble',
                        triple: 'Hab. triple'
                    },
                    personalData: 'Datos personales',
                    contactMail: 'E-Mail de contacto',
                    clubNumber: 'Nº de socio club',
                    principalTraveler: 'Viajero principal',
                    traveler: 'Viajero ',
                    travelerSubtitle: 'Rellena los datos obligatorios marcados con * para poder continuar',
                    inputName: 'Nombre y apellidos*',
                    inputNIF: 'DNI / NIE / NIF*',
                    inputOptionalNIF: 'DNI / NIE / NIF',
                    inputPhone: 'Teléfono*',
                    inputOptionalPhone: 'Teléfono (opcional)',
                    inputRoom: 'Habitación',
                    tax: 'Incluye impuestos',
                    pay: 'Pagar',
                    termsAccept: 'Acepto los ',
                    termsConditions: 'Términos y condiciones de la reserva',
                    covid: 'Todos los viajeros tienen el certificado de vacunación COVID',
                    newsletter: 'Deseo recibir información con ofertas y viajes',
                    kidsAdviseFirstPart: 'En el caso de viajar con niños o tener algún tipo de necesidad especial (ya sea problemas de movilidad, alergias alimentarias, etc) contacta con nosotros al teléfono',
                    kidsAdviseSecondPart: ' o por correo electrónico a ',
                    restantTime: 'Tiempo restante para finalizar tu reserva:',
                    minut: 'minuto',
                    minuts: 'minutos',
                    required:'Obligatorio',
                    minleters: 'Min 3 letras',
                    minchar: 'Min 3 carácteres',
                    invaliddni: 'DNI/NIF no válido',
                    invalidphone: 'Teléfono no válido',
                    invalidmail: 'E-Mail no válido'
                }
            },
            confirmation: {
                title: 'Confirmación de reserva',
                errortitle: 'Error en el pago',
                errormessage:'El pago no ha podido confirmarse, y la reserva está sin finalizar, recuerda que dispones de 10 minutos para seleccionar una de las siguientes opciones',
                date: 'Fecha',
                duration: 'Duración',
                price: 'Precio',
                code: 'Código de reserva',
                print: 'Imprimir',
                info: 'Información del viaje',
                resume: 'Resumen de la reserva',
                terms: 'Términos y condiciones',
                contact: 'Datos de contacto',
                personalData: {
                    title: 'Datos personales',
                    name: 'Nombre',
                    phone: 'Teléfono',
                    total: 'Total',
                    mail: 'E-Mail'
                },
                passenger: {
                    title: 'Pasajeros',
                    name: 'Nombre',
                    nif: 'DNI',
                    phone: 'Teléfono',
                    seat: 'Asiento',
                    room: 'Habitación'
                }
            },
            email: {
                thanks: 'Gracias por comprar en Viatges Kariba',
                received: '¡Hemos recibido tu reserva con mucha ilusión!',
                details: 'A continuación encontrarás la información de tu reserva'
            }
        }),

        new MLanguage('Cat').create({
            news: 'NOTICIES',
            contact: 'CONTACTE',
            covid: 'COVID-19',
            publicTransport: 'TRANSPORT PÚBLIC',
            busRent: "LLOGER D'AUTOCARS",
            travelAgency: 'AGÈNCIA DE VIATGES',
            school: 'SERVEIS ESCOLARS',
            karibaClaim: 'Quan cada instant del viatge es converteix en un gran record',
            services: {
                title: 'SERVEIS',
                publicTransport: 'Transport públic',
                busRent: "Lloguer d'autocars",
                travelAgency: 'Agència de viatges',
                school: 'Serveis escolars',
            },
            info: {
                title: "T'INFORMEM",
                schedules: 'Consulta els horaris',
                faq: 'FAQ',
                objects: 'Objectes perduts',
                lastNews: 'Últimes notícies'
            },
            kariba: {
                title: 'KARIBA',
                travel: 'Viatges',
                search: 'Cercador d’ofertes',
                newsletter: 'Newsletter',
                conditions: 'Condicions de viatgers'
            },
            about: {
                title: 'QUI SOM?',
                enterprise: 'Empresa',
                flota: 'Flota',
                certifications: 'Certificacions',
                workWithUs: 'Treballa amb nosaltres',
                contact: 'Contacte'
            },
            newsletter: {
                thanks: 'Gràcies per subscriure\'t a la newsletter de Kariba',
                title: 'Subscriu-t’hi per rebre les últimes promocions i viatges',
                email: 'Introdueix la teva adreça electrònica',
                button: 'Subscriure-m’hi'
            },
            legal: 'Avis legal',
            terms: "Política de privacitat",
            map: 'Mapa web',
            cookies: 'Política de cookies',
            newsletterFooter:{
                title:'Subscriu-te al newsletter de Viatges Kariba per rebre les últimes notícies i ofertes',
                name: 'NOM',
                phone: 'TELÈFON',
                email: 'ADREÇA ELECTRÒNICA',
                read: 'He llegit i accepto els ',
                terms: 'termes i les condicions'
            },
            travelCard: {
                lastSeats: 'Últimes places disponibles',
                reservar: '+ INFO',
                outOfStock: 'Exhaurit',
                shareMessage: 'Mira aquest viatge que et vaig trobar a Kariba:'
            },
            travel: {
                link: 'Veure tots els viatges >',
                head: {
                    date: 'Data: ',
                    duration: 'Durada: ',
                    price: 'Preu: ',
                    suplement: 'Suplement: ',
                    reservar: 'Reservar',
                    day: 'dia',
                    days: 'dies'
                },
                terms: 'Termes i condicions del viatge',
                busSeats: {
                    door: 'Porta',
                    driver: 'Conductor',
                    reserved: 'Reservat'
                },
                form: {
                    title: 'Reserva el teu viatge ara',
                    persons: 'Selecciona el nombre de persones',
                    personsSelect: 'Quantes persones viatjareu?',
                    seats: 'Selecciona els seients',
                    busySeat: 'No disponible',
                    freeSeat: 'Lliure',
                    reservatedSeat: 'Els meus seients',
                    rooms: {
                        title: 'Selecciona el nombre d’habitacions',
                        sup: 'Suplement',
                        indiv: 'Hab. individual',
                        double: 'Hab. doble',
                        triple: 'Hab. triple'
                    },
                    personalData: 'Dades personals',
                    contactMail: 'Adreça electrònica de contacte',
                    clubNumber: 'Número de soci club',
                    principalTraveler: 'Viatger principal',
                    traveler: 'Viatger',
                    travelerSubtitle: 'Emplena les dades obligatòries marcades amb * per poder continuar',
                    inputName: 'Nom i cognoms*',
                    inputNIF: 'DNI / NIE / NIF*',
                    inputOptionalNIF: 'DNI / NIE / NIF',
                    inputPhone: 'Telèfon*',
                    inputOptionalPhone: 'Telèfon (opcional)',
                    inputRoom: 'Habitació',
                    tax: 'Inclou impostos',
                    pay: 'Pagar',
                    termsAccept: 'Accepto els ',
                    termsConditions: 'termes i les condicions de la reserva',
                    covid: 'Tots els viatgers tenen el certificat de vacunació Covid',
                    newsletter: 'Vull rebre informació d’ofertes i viatges',
                    kidsAdviseFirstPart: 'En cas que es viatgi amb nens o hi hagi alguna mena de necessitat especial (ja siguin problemes de mobilitat, al·lèrgies alimentàries, etc.), contacta amb nosaltres al telèfon',
                    kidsAdviseSecondPart: ' o per correu electrònic a ',
                    restantTime: 'Temps restant per finalitzar la teva reserva:',
                    minut: 'minut',
                    minuts: 'minuts',
                    required:'Obligatori',
                    minleters: 'Min 3 lletres',
                    minchar: 'Min 3 caracters',
                    invaliddni: 'DNI/NIF no vàlid',
                    invalidphone: 'Telèfon no vàlid',
                    invalidmail: 'E-Mail no vàlid'
                }
            },
            confirmation: {
                title: 'Confirmació de reserva',
                errortitle: 'Error en el pagament',
                errormessage:"El pagament no s'ha pogut confirmar, i la reserva està sense finalitzar, recorda que disposes de 10 minuts per seleccionar una de les opcions següents",
                date: 'Data',
                duration: 'Durada',
                price: 'Preu',
                code: 'Codi de reserva',
                print: 'Imprimir',
                info: 'Informació del viatge',
                resume: 'Resum de la reserva',
                terms: 'Termes i condicions',
                contact: 'Dades de contacte',
                personalData: {
                    title: 'Dades personals',
                    name: 'Nom',
                    phone: 'Telèfon',
                    total: 'Total',
                    mail: 'Adreça electrònica'
                },
                passenger: {
                    title: 'Passatgers',
                    name: 'Nom',
                    nif: 'DNI',
                    phone: 'Telèfon',
                    seat: 'Seient',
                    room: 'Habitació'
                }
            },
            email: {
                thanks: 'Gràcies per comprar a Viatges Kariba',
                received: 'Hem rebut la teva reserva amb molta il·lusió!',
                details: 'A continuació trobaràs la informació de la teva reserva'
            }
        })
    ]
})