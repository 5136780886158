<template>
  <v-row justify="center" class="headWhite">
    <v-col cols="4" style="left: -30px;position: relative;">
      <a href="https://autocarsgamon.com/" rel="nofollow" target="_blank">
        <v-img src="https://autocarsgamon.com/wp-content/uploads/2019/08/LOGO-Gamon-grup.png" width="120"
               class="text-left"/>
      </a>
    </v-col>
    <v-col cols="4" style="position: relative;right: -75px;">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">mdi-menu
          </v-icon>
        </template>
        <v-list>
          <v-list-item class="menuItemMobile">
            <a href="https://autocarsgamon.com/linies-regulars/" rel="nofollow" target="_blank"
               v-text="$ml.get('publicTransport')"/>
          </v-list-item>
          <v-list-item class="menuItemMobile">
            <a href="https://autocarsgamon.com/lloguer-dautocars-serveis-a-mida/" rel="nofollow" target="_blank"
               v-text="$ml.get('busRent')"/>
          </v-list-item>
          <v-list-item class="menuItemMobile">
            <a :href="env_url" rel="nofollow" target="_blank" v-text="$ml.get('travelAgency')"/>
          </v-list-item>
          <v-list-item class="menuItemMobile">
            <a href="https://autocarsgamon.com/escolabus-serveis-escolars/" rel="nofollow" target="_blank"
               v-text="$ml.get('school')"/>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "headWhiteMobile"
}
</script>

<style scoped>
.headWhite {
  background: white;
  z-index: 1;
}
</style>