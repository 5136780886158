<template>
  <v-col cols="12" style="background: #D52787">
    <v-row justify="center" align="center" v-if="!sendOK">
      <v-col cols="10">
        <h2 class="newsTitle" style="text-align: left;margin-bottom: -2em" v-text="$ml.get('newsletter.title')" />
      </v-col>
      <v-col cols="10" md="6" v-bind:class="{'mt-5':isMobile()}">
        <v-text-field
            :label="$ml.get('newsletter.email')"
            style="margin-top: 2em;margin-bottom: -1em;"
            solo/>
      </v-col>
      <v-col cols="10" md="4" align="center" v-bind:class="{'mt-n5':isMobile()}">
        <v-btn :loading="load" @click="sendNews" outlined color="white" v-bind:class="{'pa-6':!isMobile(),'pa-3':isMobile()}"  v-text="$ml.get('newsletter.button')" />
      </v-col>
    </v-row>
    <v-row  justify="center" align="center" class="pa-10"  v-else>
      <v-col cols="10">
        <h2 class="newsTitle" style="text-align: left;margin-bottom: -2em" v-text="$ml.get('newsletter.thanks')" />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "newslettertopsection",
  data(){
    return{
      acceptTerms:false,
      sendOK:false,
      load:false
    }
  },
  methods:{
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    sendNews(){
      this.load = true;
      setTimeout(function () { this.sendOK = true; this.load = false; }.bind(this), 1500)
    }
  }

}
</script>

<style scoped>
.newsTitle {
  padding: 10px 10px 10px 10px;
  background-color: #d81d86;
  font-family: "Playfair Display", Sans-serif;
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 30px;
}
</style>