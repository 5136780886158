<template>
  <VideoParallax height="300" img="assets/city.png" src="/assets/gamon.mp4">
    <v-row
        justify="center">
      <v-col
          class="text-right justify-end"
          cols="12" md="4">
        <router-link to="/">
          <v-img src="../../assets/kariba.png" width="300" class="ml-15 mt-15 text-right"/>
        </router-link>
      </v-col>
      <v-col
          class="text-center mt-15"
          cols="12" md="6">
        <h2 class="subheading" v-text="$ml.get('karibaClaim')"/>
      </v-col>
    </v-row>
  </VideoParallax>
</template>

<script>
import VideoParallax from 'vuetify-video-parallax';

export default {
  name: "videoDesktop",
  components:{    VideoParallax}
}
</script>

<style scoped>

</style>